@import '../../styles/variables';

.lp-wrapped-text-area {
  cursor: text;
  min-height: 120px;

  > .lp-legend-wrapper-content {
    max-height: calc(100% - 20px);

    .lp-wrapped-text-area-field {
      flex: 1;
      border: none;
      padding: 0;
      color: inherit;
      -webkit-text-fill-color: inherit;
      font: inherit;
      line-height: $font-regular-line-height;
      background-color: transparent;
      outline: 0;
      resize: none;
      max-height: 100%;

      &::placeholder {
        opacity: 1;
        color: $color-dark30;
      }
    }
  }
}
