@import '~leanplum-lib-ui/src/styles/variables';

.lp-list-view-wrapper {
  overflow-y: auto;
  padding: $spacing-regular; // add padding to accommodate drop shadows.
  /* These two fix the scroll bar disappearing in Chrome */
  position: relative;
  z-index: 1;

  &.disabled {
    .list-item {
      color: $color-dark30;
      cursor: default;
    }
  }

  &.scrollable {
    &::after, // always show bottom blur
    &.scrolled::before {
      background: #fff;
      box-shadow: 0 0 10px 10px #fff;
      content: '';
      display: block;
      height: 0;
      position: sticky;
      width: 100%;
      z-index: 1;
    }

    &::after {
      bottom: -5px;
    }

    &.scrolled::before {
      top: -5px;
    }

    &.scrolled-to-end::after {
      display: none;
    }
  }

  .lp-pagination {
    margin-top: $spacing-medium;
  }
}

.lp-list-view {
  margin: 0;
  padding: 0;
  list-style: none;

  > .list-item {
    position: relative; // to enable z-index
    display: flex;
    align-items: center;
    width: 100%;
    min-height: $size-item-regular;
    line-height: $font-regular-line-height;
    background-color: $color-white;
    color: $color-dark90;
    cursor: pointer;
    border: 1px solid transparent;

    &::after {
      @include hoverShadow();

      border-radius: $radius-regular;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;

      z-index: 1;

      opacity: 0;
      @include transitionAppearance(opacity);
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &.active {
      border-radius: $radius-regular;
      border-color: $color-blue;

      &::after {
        box-shadow:
            0px 2px 8px 0px rgba(16, 118, 251, 0.08),
            0px 0px 1px 0px rgba(16, 118, 251, 0.08),
            2px 6px 12px 0px rgba(16, 118, 251, 0.08);
      }
    }
  }
}
