@import '../../styles/variables';

.lp-calendar {
  background-color: $color-white;
  user-select: none;
  overflow: hidden;
  width: fit-content;
  height: 340px;
  padding: $spacing-large;

  &.shadow {
    @include dropdownShadow();
    border-radius: $radius-regular;
    border: 1px solid $color-light10;
  }

  .lp-calendar-header {
    text-align: center;
    padding-top: 12px;

    .lp-calendar-controls {
      display: inline-flex;
      padding: 0 4px;
      width: 100%;

      position: relative;

      .current {
        flex: 1 1 100%;
        display: flex;
        align-items: center;
        margin-top: 0;
        font-size: 14px;
        color: $color-dark90;
        font-weight: bold;

        .text {
          flex: 1 0 auto;
          padding: 0 4px;
          cursor: default;
        }
      }

      .nav {
        display: flex;
        position: relative;
        width: 30px;
        min-width: 30px;
        height: 30px;
        min-height: 30px;
        margin: 0 4px;
        padding-top: 1px;
        border-radius: 100%;
        cursor: pointer;
        color: $color-dark70;
        stroke-width: 0.25;
        @include transitionAppearance(color);

        &:after {
          @include transitionAppearance(background-color, opacity);
          position: absolute;
          border-radius: 100%;
          opacity: 0;
          top: 1px;
          left: 1px;
          content: '';
          height: 28px;
          width: 28px;
          z-index: 0;
        }

        &:hover {
          color: $color-dark90;

          &:after {
            opacity: 1;
            background-color: $color-light10;
          }
        }

        > .lp-icon {
          align-self: center;
          margin: 0 auto;
          z-index: 1;
        }
      }
    }
  }

  .lp-calendar-month {
    padding: 0px 4px 4px 4px;
    text-align: center;

    .lp-calendar-day-names {
      padding-top: 12px;
      margin-bottom: -2px;

      .day-name {
        display: inline-block;
        font-size: 13px;
        color: $color-dark30;
        width: 30px;
        height: 30px;
        line-height: 36px;
        text-align: center;
        margin: 4px;
      }
    }

    .lp-calendar-week {
      display: flex;
    }
  }
}
