@import '../../styles/variables';

.lp-icon {
  font-style: normal;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &.clickable {
    cursor: pointer;
  }

  &.circle {
    &.info {
      color: $color-dark90;
    }

    &.warning {
      color: $color-yellow;
    }

    &.danger {
      color: $color-red;
    }

    &.success {
      color: $color-green;
    }

    &.light {
      color: $color-light10;
    }

    &.help {
      color: $color-dark30;
    }
  }

  .icon-svg-container {
    flex: 1;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;

    > svg {
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
