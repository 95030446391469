.color-slider {
  position: relative;

  > .bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.hue {
    > .bar {
      background: linear-gradient(to bottom, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
    }
  }

  &.alpha {
    > .bar {
      background: linear-gradient(to right, rgba(1, 1, 1, 0) 0%, rgb(255, 255, 255) 100%);
    }
  }

  &.saturation {
    > .bar {
      > .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &.white {
          background: linear-gradient(90deg, #FFFFFF, hsla(0, 0%, 100%, 0));
        }
        &.black {
          background: linear-gradient(0deg, #000000, transparent);
        }
      }
    }
    > .thumb {
      width: 14px;
      height: 14px;
      border: 2px solid #FFFFFF;
      background: #FFFFFF;
      box-shadow: none;
      border-radius: 100%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  > .thumb {
    pointer-events: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    border-radius: 1px;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}
