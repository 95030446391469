@import '~leanplum-lib-ui/src/styles/variables';

.menu-item-group-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: $spacing-xxxlarge;
    margin-right: $spacing-xxlarge;
  }

  > .group-header {
    display: flex;
    margin-bottom: $spacing-regular;
    align-items: center;

    > h3 {
      @include textTitleUppercase();
      color: $color-primary;
      margin: 0;
      line-height: $font-regular-line-height;
    }

    > h4 {
      margin: 0 0 0 $spacing-regular;
      color: $color-dark30;
      font-size: $font-small;
      font-weight: 400;
    }
  }

  .menu-item-group {
    flex-flow: column wrap;
    width: 100%;

    > ul {
      display: flex;
      flex-flow: row wrap;
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        margin: 0 0 0 -1 * $spacing-regular;
        padding: 0 0 0 $spacing-regular;
        padding-right: $spacing-xxlarge;
        line-height: $font-regular-line-height;
        border-radius: $radius-regular;

        &:not(.disabled):hover {
          background-color: $color-light02;

          .menu-item-link {
            .menu-item {
              color: $color-primary;
            }

            .menu-item-description {
              color: $color-dark70;
            }
          }
        }

        &.disabled {
          .menu-item-link {
            .menu-item {
              color: $color-dark30;
            }
            cursor: default;
          }
        }

        .menu-item-link {
          .menu-item {
            display: flex;
            align-items: center;
            color: $color-dark70;
            line-height: $font-regular-line-height;

            width: 100%;
            padding-bottom: $spacing-medium / 2;
            padding-top: $spacing-medium / 2;
          }

          .menu-item-description {
            @include textExplanatory();
            color: $color-dark30;
            padding-bottom: $spacing-small;
          }
        }
      }
    }
  }

  .lp-button {
    margin-top: $spacing-regular;
  }
}
