@import '../../styles/variables';

.overflowable-text {
  @include ellipsis();
  word-break: break-word;
}

.overflowable-text-tooltip-text {
  word-break: break-word;
}

.overflowable-text-tooltip-separator {
  width: calc(100% + #{2 * $spacing-medium});
  border-bottom: 1px solid $color-white;
  margin: $spacing-regular -1 * $spacing-medium;
}

.overflowable-subtitle {
  position: relative;
  top: -1 * $spacing-small;
  color: $color-dark30;
  @include textExplanatory();
}
