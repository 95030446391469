@import '../../../styles/variables';

.lp-time-input {
  .lp-time-input-controls {
    display: flex;
    width: 100%;

    .separator {
      margin: 0;
      min-height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .lp-time-input-footer {
    padding: $spacing-medium 0 0 0;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;

    .lp-button {
      flex: 0 1 auto;
    }
  }
}
