@import "../../styles/variables.scss";

.lp-split-view-layout {
  display: flex;

  &.loading {
    > .split-view-left {
      .content::after,
      .content::before {
        content: " ";
        display: block;
        margin-top: 20px;
        width: 100%;
        height: 20px;
        @include loading(false);
      }
    }
  }

  > .split-view-left {
    width: 40%;
    min-width: 520px;
    max-width: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 6px;
    overflow: hidden;
    position: relative;

    > .content {
      $content-padding: 28px;

      flex: 1 1 auto;
      padding: $content-padding;
      overflow-x: hidden;
      background-color: $color-white;
      box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.05);
      border-radius: $radius-regular;
    }
  }

  > .split-view-right {
    width: 60%;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }
}
