@import '../../styles/variables';

.lp-radio {
  $icon-size: $size-icon-regular;
  $icon-spacing: $spacing-small;

  display: flex;

  > .container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    cursor: pointer;

    > .fake-input {
      display: block;
      box-sizing: border-box;
      width: $icon-size;
      height: $icon-size;
      padding: $icon-spacing;
      border: 1px solid transparent;
      border-radius: 100%;
      background-color: transparent;
      @include transitionAppearance(border-color, background-color);

      > .real-input {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        cursor: pointer;
        opacity: 0;
      }
      > .check-icon {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        border-radius: 100%;
        background-color: $color-white;
        @include transitionVisibility(opacity);
      }
    }
    > .text {
      flex: 1;
      margin-left: $spacing-regular;
      line-height: 24px;
      color: $color-dark70;
    }

    > .description {
      @include textExplanatory();

      padding-left: ($icon-size + 2*$icon-spacing);
      color: $color-dark30;
      flex: 1 1 100%;
    }

    &::after {
      content: '';
      order: 4;
      flex: 1 0 100%;
    }


    &.checked {
      > .fake-input {
        border-color: $color-primary;

        > .check-icon {
          background-color: $color-primary;
          opacity: 1;
        }
      }
    }
    &.unchecked {
      > .fake-input {
        border-color: $color-dark30;

        > .check-icon {
          opacity: 0;
        }
      }
    }
    &.disabled {
      pointer-events: none;
      opacity: .5;

      &.checked {
        .check-icon {
          opacity: 1;
        }
      }
    }
    &:hover:not(.disabled) {
      > .text {
        color: $color-dark90;
      }

      &.checked {
        > .fake-input {
          border-color: $color-primary-hover;

          > .check-icon {
            background-color: $color-primary-hover;
            opacity: 1;
          }
        }
      }
      &.unchecked {
        > .fake-input {
          border-color: shade($color-dark30, 25%);

          > .check-icon {
            opacity: 0;
          }
        }
      }
    }
    &:active {
      &.checked {
        > .fake-input {
          border-color: $color-primary-hover;

          > .check-icon {
            opacity: 0;
          }
        }
      }
      &.unchecked {
        > .fake-input {
          border-color: $color-dark30;

          > .check-icon {
            opacity: 1;
          }
        }
      }
    }
  }
}
