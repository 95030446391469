@import "../../styles/variables.scss";

.error-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  margin: 20px;
  z-index: $zindex-global-error;

  > .error {
    position: relative;
    border: none;
    border-radius: $radius-regular;
    box-shadow: 0px 4px 20px 0 rgba(0,0,0,0.2);
    background-color: $color-white;
    padding: 36px 40px 40px 40px;
    margin: 0;
    max-width: 320px;
    opacity: 1;
    top: 0;

    &.v-enter-active {
      @include transitionVisibility(opacity, top);
    }
    &.v-enter {
      opacity: 0;
      top: -20px;
    }
    > .close {
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 16px;
      font-size: 24px;
      position: absolute;
      top: 15px;
      right: 15px;
      color: $color-dark30;
      cursor: pointer;

      &:hover {
        color: $gray-dark;
      }
    }
    > .content {
      > .title {
        font-family: $font-family-circular-bold;
        color: $black;
        font-size: 18px;
        line-height: 20px;
        margin: 0 0 10px;
      }

      > .text {
        word-break: break-word;
      }
    }
  }
}
