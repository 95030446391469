@import '../../styles/variables';

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0.25);
  } 40% {
    transform: scale(0.75);
  }
}

.bounce-spinner-view {
  margin: 0 auto;
  display: flex;
  flex-direction: row;

  > .bouncer {
    width: 16px;
    height: 16px;
    margin: auto;
    background-color: $color-dark30;
    border-radius: 100%;
    vertical-align: middle;
    animation: bounce 1s infinite ease-in-out;
    animation-fill-mode: both;
  }
}
