@import '../../styles/variables';

.lp-anchored-popup {
  z-index: $zindex-portal;

  .lp-anchored-popup-content {
    @include animationVisibility(appear);
    will-change: transform opacity;
    animation-fill-mode: forwards;

    &.disappear {
      @include animationVisibility(disappear);
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0.98);
    pointer-events: none;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
    transform: scale(1);
    pointer-events: none;
  }
  100% {
    opacity: 0;
    transform: scale(0.98);
  }
}
