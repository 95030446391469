@import '../../styles/variables';

.copy-text {
  display: inline-block;

  &:hover {
    cursor: pointer;
  }

  .icon-copied {
    color: $color-green;
  }
}
