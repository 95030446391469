.google-map-container {
  width: 100%;
  height: 100%;
  position: relative;

  > .map-element {
    width: 100%;
    height: 100%;
  }
}
