@import '../../styles/variables';

$row-min-height: 48px;
$bounding-section-height: 69px;

.lp-table {
  overflow: hidden;
  background-color: $color-white;
  position: relative;
  z-index: 0;
  display: flex;
  flex-flow: column nowrap;

  &.embedded {
    .lp-table-actions {
      height: $bounding-section-height - $spacing-large;
      padding: 0 0 $spacing-large;
    }

    .lp-table-body:not(.x-scrollable) {
      overflow-y: auto;
    }

    .lp-table-footer {
      height: $bounding-section-height - $spacing-large;
      padding-bottom: 0;
    }
  }

  &:not(.embedded) {
    @include dropdownShadow();
    border-radius: $radius-regular;

    .lp-table-body {
      margin-left: $spacing-xxlarge;
      // accommodate scrollbar space for firefox
      // TODO fix this because it offsets scrollbar track
      @-moz-document url-prefix() {
        margin-right: $spacing-xxlarge;
      }
    }

    .lp-table-actions {
      padding: $spacing-large $spacing-xxlarge;
    }

    .lp-table-footer {
      margin: 0 $spacing-xxlarge;
    }
  }

  .lp-table-actions {
    display: flex;

    align-items: center;
    height: $bounding-section-height;
    border-bottom: 1px solid $color-light10;
    color: $color-dark90;
    background-color: $color-white;
    position: relative;
    z-index: 3;
    flex-shrink: 0;

    > *:not(:first-child) {
      margin-left: $spacing-regular;
    }

    > .lp-table-actions-left {
      display: flex;
      gap: $spacing-medium;
      align-items: center;
    }

    > .lp-table-actions-right {
      display: flex;
      gap: $spacing-medium;
      margin-left: auto;
      align-items: center;
    }
  }

  .lp-table-body {
    overflow-y: scroll;
    display: grid;
    grid-auto-rows: max-content;
    height: 100%;

    &.x-scrollable {
      overflow-x: auto;

      &::after {
        @include transitionVisibility(opacity);
        position: absolute;
        right: 0;
        top: 0;
        content: '';
        height: 100%;
        width: 24px;
        box-shadow: 0 0 $spacing-large 0 rgba(0, 0, 0, 0.1);
        opacity: 1;
        will-change: opacity;
        z-index: 2;
        pointer-events: none;

        // white scrollbar background for firefox
        @-moz-document url-prefix() {
          background-color: $color-white;
        }
      }

      .lp-table-row,
      .lp-table-header {
        .lp-table-cell.sticky {
          position: sticky;
          z-index: 1;
          background-color: $color-white;

          // Cover case where Table is selectable and we need first 2 columns to be sticky
          &:not(.lp-table-select-cell) {
            padding-right: $spacing-large;

            &::after {
              // Accommodate some offset because Safari's inset box-shadow
              // implementation breaks with too small negative spread values
              $safari-offset: 31px;
              @include transitionVisibility(opacity);
              opacity: 0;
              content: '';
              box-shadow: $spacing-large 0 $spacing-large -1 * $spacing-large rgba(0, 0, 0, 0.1) inset;
              width: $spacing-large + $safari-offset;
              right: -1 * ($spacing-large + $safari-offset);
              top: 0;
              height: 100%;
              position: absolute;
              will-change: opacity;
              pointer-events: none;
            }
          }

          &:first-child {
            left: 0;
          }

          + .sticky {
            $select-column-width: 33px;
            left: $select-column-width;
          }
        }
      }

      .lp-table-header {
        .lp-table-cell.sticky {
          z-index: 3;
        }
      }
    }

    &:not(.x-scrollable) {
      overflow-x: hidden;
    }

    &.x-scrolled {
      .lp-table-row,
      .lp-table-header {
        .lp-table-cell.sticky:not(.lp-table-select-cell)::after {
          opacity: 1;
        }
      }
    }

    &.x-scrolled-end {
      &::after {
        opacity: 0;
      }
    }

    .lp-table-header {
      display: contents;
      color: $color-dark30;
      white-space: nowrap;

      .lp-table-cell {
        position: sticky;
        top: 0;
        border-bottom: 1px solid $color-light10;
        user-select: none;
        background-color: $color-white;
        z-index: 2;

        &.sortable {
          $sort-icon-width: 20px;

          @include transitionAppearance(color);
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          cursor: pointer;

          &:hover,
          &.sorted {
            .lp-table-sort-icon {
              opacity: 1;
            }

            &.center,
            &.end {
              .lp-table-header-cell-content {
                transform: translateX(0);
              }
            }
          }

          &.center {
            .lp-table-header-cell-content {
              @include transitionVisibility(transform);
              will-change: transform;
              transform: translateX($sort-icon-width / 2);
            }
          }

          &.end {
            .lp-table-header-cell-content {
              @include transitionVisibility(transform);
              will-change: transform;
              transform: translateX($sort-icon-width);
            }
          }

          .lp-table-sort-icon {
            @include transitionVisibility(opacity, transform);
            opacity: 0;
            transform: rotate(0deg);
            will-change: opacity, transform;

            &.inverted {
              transform: rotate(180deg);
            }
          }
        }
      }

      .highlighted {
        color: $color-dark90;
      }
    }

    .lp-table-row {
      display: contents;
      color: $color-dark70;

      &.clickable {
        cursor: pointer;
      }

      &:hover {
        .lp-table-item-menu {
          opacity: 1;
          pointer-events: all;
        }
      }

      @mixin rowHoverBackgroundColor($background, $label) {
        &.hoverable:hover {
          .lp-table-cell {
            background-color: $background;

            .lp-pill .label {
              background-color: $label;
              border-color: $label;
            }
          }
        }

        .lp-table-item-menu {
          background-color: $background;

          &::before {
            background: linear-gradient(to left, #{$background}, rgba(255, 255, 255, 0));
          }
        }
      }

      &.striped {
        $stripped-hover-color: $color-light02;
        $label-hover-color: $color-light10;
        @include rowHoverBackgroundColor($stripped-hover-color, $label-hover-color);

        &:nth-child(odd) {
          .lp-table-cell {
            background-color: $color-light02;
          }
        }
      }

      &.bordered {
        $bordered-hover-color: $color-light02;
        $label-hover-color: $color-light10;
        @include rowHoverBackgroundColor($bordered-hover-color, $label-hover-color);

        .lp-table-cell {
          // accommodate border
          min-height: $row-min-height + 1px;
        }

        &:not(:nth-child(2)) {
          .lp-table-cell {
            border-top: 1px solid $color-light10;
          }
        }
      }

      .lp-table-cell.item-menu-wrapper {
        position: relative;
        height: 100%;
        margin-left: auto;
      }

      .lp-table-item-menu {
        position: absolute;
        top: 1px;
        bottom: 1px;
        right: $spacing-large;
        display: flex;
        align-items: center;
        opacity: 0;
        @include transitionVisibility(opacity);
        z-index: 1;

        &.v-enter {
          opacity: 0;
        }

        &::before {
          $width: 40px;
          content: '';
          position: absolute;
          left: -$width;
          height: 100%;
          width: $width;
        }
      }
    }

    .lp-table-cell {
      @include textRegular();
      @include transitionAppearance(background-color);
      width: 100%;
      height: 100%;
      min-height: $row-min-height;
      display: flex;
      align-items: center;

      &.start {
        justify-content: start;
      }

      &.center {
        justify-content: center;
      }

      &.end {
        justify-content: end;
      }

      &.stretch {
        justify-content: stretch;
      }

      &:not(.lp-table-select-cell) {
        padding-left: $spacing-large;
      }

      &:last-child {
        padding-right: $spacing-large;
      }

      .lp-table-loading-placeholder {
        @include loadingPlaceholder();
      }
    }

    .lp-table-select-cell {
      cursor: pointer;

      .lp-checkbox {
        margin-left: $spacing-large;
      }
    }

    &::-webkit-scrollbar:vertical {
      width: 24px;
    }

    &::-webkit-scrollbar-thumb:vertical {
      border: 8px solid white;
      border-radius: 32px;
    }

    &.highlightable {
      .lp-table-row {
        .lp-table-cell {
          position: relative;

          &::before {
            @include transitionAppearance(opacity);
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            border-top: 1px solid $color-primary;
            border-bottom: 1px solid $color-primary;
            left: 0;
            opacity: 0;
            pointer-events: none;
          }

          &:first-child {
            &::before {
              border-radius: $radius-regular 0 0 $radius-regular;
              border-left: 1px solid $color-primary;
            }
          }

          &:last-child {
            &::before {
              border-radius: 0 $radius-regular $radius-regular 0;
              border-right: 1px solid $color-primary;
            }
          }
        }

        &.highlighted {
          $highlighted-color: mix($color-blue, $color-white, 4%);

          .lp-table-cell {
            background-color: $highlighted-color;

            &::before {
              opacity: 1;
            }

            &:first-child {
              color: $color-primary;
            }
          }
        }
      }
    }
  }

  .lp-table-footer {
    display: flex;
    align-items: center;
    background-color: $color-white;
    padding: $spacing-large 0;
    border-top: 1px solid $color-light10;
    height: $bounding-section-height;
    position: relative;
    z-index: 2;
    flex-shrink: 0;

    .lp-table-footer-pagination-info {
      color: $color-dark30;
    }

    .lp-table-footer-selection-info {
      @include textBold();
    }

    .lp-table-footer-bulk-select-action {
      color: $color-primary;
      cursor: pointer;
      margin-left: $spacing-large;
    }

    .lp-pagination {
      margin-left: auto;
    }

    .lp-table-items-picker {
      color: $color-dark30;
      display: flex;
      align-items: center;
      white-space: nowrap;

      > .lp-table-items-picker-items {
        margin-right: $spacing-medium;
      }

      > .lp-table-items-picker-dropdown {
        padding-right: $spacing-small;
      }
    }
  }

  .lp-table-footer-accommodation {
    height: $spacing-xxlarge;
    background-color: $color-white;
    position: relative;
    margin: 0 $spacing-xxlarge;
    border-top: 1px solid $color-light10;
    z-index: 3;
  }

  .lp-placeholder-pane {
    margin: 88px 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
