@import '~leanplum-lib-ui/src/styles/variables';

.lp-number-input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid $color-light02;
  border-radius: $radius-regular;
  height: $size-item-regular;
  font-size: $font-regular;
  line-height: $font-regular-line-height;
  padding: $spacing-regular $spacing-large;
  background-color: $color-light02;
  color: $color-dark70;
  position: relative;

  @include transitionAppearance(border-color, background-color);

  &:hover {
    color: $color-dark90;
    background-color: $color-light10;
  }

  &.disabled {
    border: 1px solid $color-light02;
    background-color: $color-light02;
    color: $color-dark30;
  }

  &.error {
    border: 1px solid $color-red;
  }

  &.warning {
    border: 1px solid $color-yellow;
  }

  &:active, &:disabled {
    outline: 0;
  }

  &:focus-within {
    border: 1px solid $color-primary;
    background-color: $color-white;
    color: $color-dark90;
    outline: 0;

    &.error {
      border: 1px solid $color-red;
    }

    &.warning {
      border: 1px solid $color-yellow;
    }
  }

  .hide-arrows {
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance:textfield;
    }
  }

  > .lp-number-input {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 30px;
    outline: none;
    background: transparent;
    border: 0;
    line-height: $font-regular-line-height;
    cursor: text;


    &::placeholder {
      @include transitionAppearance(color);
      opacity: 1;
      color: $color-dark30;
    }

    &:hover {
      color: $color-dark90;
    }

    &:disabled {
      background-color: $color-light02;
      color: $color-dark30;

      &::placeholder {
        opacity: 1;
      }
    }
  }
}

.lp-wrapped-number-input {
  .lp-wrapped-number-input-field {
    &.hide-arrows {
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type=number] {
        -moz-appearance:textfield;
      }
    }

    flex: 1;
    border: none;
    padding: 0;
    color: inherit;
    -webkit-text-fill-color: inherit;
    font: inherit;
    line-height: $font-regular-line-height;
    height: $font-regular-line-height;
    background-color: transparent;
    outline: 0;

    &::placeholder {
      opacity: 1;
      color: $color-dark30;
    }
  }
}

.shake {
  @include shake();
}
