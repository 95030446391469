.device-scale-wrapper {
  display: block;
	width: 100%;
	height: 100%;
	resize: both;
	position: relative;
	box-sizing: border-box;
}

.device-preview-frame {
  box-sizing: border-box;
	display: flex;
  align-items: center;
  justify-content: center;
	border: 10px solid #191919;
	border-radius: 62px;
	background-color: black;
	box-sizing: content-box;
	box-shadow: inset 0 0 2px rgba(255, 255, 255, 1), 0 80px 80px 5px rgba(0, 0, 0, 0.1);
	position: relative;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transform-origin: center center;
  text-align: center;
}
